<script lang="ts">
import transfer from '~/assets/icons/transfer.svg'
import tronlink from '~/assets/icons/tronlink-s5.svg'
import ttpay from '~/assets/icons/ttpay-s2.svg'
import balance from '~/assets/icons/balance.svg'
import { useUserInfo } from '~/composables/app'

export enum EPaymentMethod {
  DAPP,
  TRANSFER,
  TTPAY,
  BALANCE,
}
interface IPayType {
  icon: string
  name: string
  code: number
  codeEnum: number
  describe: string
  isShow: () => boolean
}
</script>

<script setup lang="ts">
const props = defineProps(['payType'])
const emits = defineEmits(['change'])
const { hasLogged, trxMoney } = useUserInfo()
const { config } = useAppState()
// PayType
const payTypeMap: IPayType[] = [
  {
    icon: tronlink, // tronlink
    name: $t('6_05Y7X0nWiKQqen7xMiZ'),
    code: EPaymentMethod.DAPP,
    codeEnum: EPaymentMethod.DAPP,
    describe: $t('BZEju5c6ccIBGGMHehs66'),
    isShow: () => {
      return true
    },
  },
  {
    icon: transfer, // 转账
    name: $t('QnlQDhxPAdpU8ZziCOLhP'),
    code: EPaymentMethod.TRANSFER,
    codeEnum: EPaymentMethod.TRANSFER,
    describe: $t('ABBoTvHU7qTSdHy6N-e5t'),
    isShow: () => {
      return false
    },
  },
  {
    icon: ttpay,
    name: $t('rgpIJt75_6oaewUX8e0Rw'),
    code: EPaymentMethod.TTPAY,
    codeEnum: EPaymentMethod.TTPAY,
    describe: $t('uMWAaLIsUjS2QA7srpwY0'),
    isShow: () => {
      // 捂手协议关闭后这两种方式就不支持了
      if (config?.value?.base.enable_ttpay !== 1)
        return false
      return true
    },
  },
  {
    icon: balance,
    name: $t('rgpIJt75_6oaewUX8e099'),
    code: EPaymentMethod.BALANCE,
    codeEnum: EPaymentMethod.BALANCE,
    describe: `${formatUserAmount(trxMoney.value)} TRX`,
    isShow: () => hasLogged.value,
  },
]

// 状态值
const state = reactive({
  code: props.payType,
})

function onChange(i) {
  state.code = i.code
  emits('change', i.codeEnum)
}
</script>

<template>
  <div class="pay-type-main">
    <template v-for="(i, k) in payTypeMap">
      <div
        v-if="i.isShow()"
        :key="k"
        class="item" :class="[{ active: state.code === i.code }]"
        :data-test-id="`li6t${k}`"
        @click="onChange(i)"
      >
        <div class="icon">
          <img class="rocket" :src="i.icon">
        </div>
        <div class="content">
          <p class="name">{{ i.name }}</p>
          <p class="describe" :class="{ '!text-text-green': i.code === EPaymentMethod.BALANCE }">{{ i.describe }}</p>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
@mixin active {
  box-shadow: 0 0 0 2px #00c1a5;

  &::after {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 18px;
    height: 18px;
    content: '';
    background: url('@/assets/icons/active.svg');
    background-size: cover; /* 调整图片大小以适应元素 */
  }
}

.pay-type-main {
  display: flex;
  gap: 16px;
  width: 100%;

  .item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 7px;
    cursor: pointer;
    background: #2f3e6f;
    border-radius: 10px;
    box-shadow: none;

    @media (hover: hover) {
      &:hover {
        @include active;
      }
    }

    &.active {
      @include active;
    }

    .icon {
      margin-right: 10px;

      img{
        width: 26px;
      }
    }

    .content {
      flex: 1;
      font-size: 12px;

      .name {
        margin-bottom: 2px;
        font-size: 14px;
        color: #e2e8f2;
      }

      .describe {
        font-size: 12px;
        line-height: 13px;
        color: #989fae;
      }
    }
  }

  &.b2c {
    align-items: stretch;
    justify-content: space-between;

    .item {
      flex: 1;
    }
  }

  &.c2c {
    flex-direction: column;
    justify-content: center;
  }
}

@media bp-lt-tablet  {
  .pay-type-main.b2c {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .item {
      width: 100%;
    }
  }
}
</style>
